import React from "react";

const MobileShare = () => {
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "I'm sharing this page",
          text: "Online Gallery for Porinchu P A",
          url: "https://qrmedia.in/b68uq5yjv9",
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  return (
    <div className="share-icon" onClick={handleOnClick}>
      {/* <ShareIcon /> */}
      <div>Share</div>
    </div>
  );
};

export default MobileShare;
