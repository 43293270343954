import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDO-M3PkCKHcmIUd7HR-afWzHMKfU3Q7-8",
  authDomain: "qr-media.firebaseapp.com",
  projectId: "qr-media",
  storageBucket: "qr-media.appspot.com",
  messagingSenderId: "26147950807",
  appId: "1:26147950807:web:1dca4d3251198b0df36092",
  measurementId: "G-4XEEEV95LX",
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { firebaseApp, db, storage };
