import "./dummy_gallery.css";
import ResponsiveGallery from "react-responsive-gallery";
import ImageGallery from "react-image-gallery";
import { storage } from "../../firebase/config";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { useEffect } from "react";
import MobileShare from "./MobileShare";

export default function DummyGallery() {
  const obj2 = [
    { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F12.jpeg.png?alt=media&token=51a40f05-b11b-4fa3-b143-065a5e64e5bc", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F12.jpeg.png?alt=media&token=51a40f05-b11b-4fa3-b143-065a5e64e5bc" },
    { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0003.jpg.png?alt=media&token=6774b652-28cc-482c-bd07-f8676b8422b1", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0003.jpg.png?alt=media&token=6774b652-28cc-482c-bd07-f8676b8422b1" },
    { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_1.jpg.png?alt=media&token=b8142cc8-0efd-4f0b-9b97-2adfab83cfa5", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_1.jpg.png?alt=media&token=b8142cc8-0efd-4f0b-9b97-2adfab83cfa5" },
    { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_1.jpg.png?alt=media&token=a0ea0921-cfec-43d8-a97a-32cabac3c11b", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_1.jpg.png?alt=media&token=a0ea0921-cfec-43d8-a97a-32cabac3c11b" },
    { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_3.jpg.png?alt=media&token=b81d9fe8-d87c-430b-9276-cf17cf5941eb", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_3.jpg.png?alt=media&token=b81d9fe8-d87c-430b-9276-cf17cf5941eb" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_2.jpg.png?alt=media&token=330613af-c923-424f-acd2-694af4f45f0f", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_2.jpg.png?alt=media&token=330613af-c923-424f-acd2-694af4f45f0f" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0018.jpg.png?alt=media&token=008195d2-fe22-4b04-b08a-a6a7cb3b5998", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0018.jpg.png?alt=media&token=008195d2-fe22-4b04-b08a-a6a7cb3b5998" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F10.jpeg.png?alt=media&token=65029b2b-9c52-41c4-b1b3-f3e24b8709c2", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F10.jpeg.png?alt=media&token=65029b2b-9c52-41c4-b1b3-f3e24b8709c2" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F11.jpeg.png?alt=media&token=3cc672f7-2535-4706-9d98-1539809087a6", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F11.jpeg.png?alt=media&token=3cc672f7-2535-4706-9d98-1539809087a6" },
  // { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F1.jpeg.png?alt=media&token=652f149a-5674-4bce-b819-4d232b3c8b3d", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F1.jpeg.png?alt=media&token=652f149a-5674-4bce-b819-4d232b3c8b3d" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F4.jpeg.png?alt=media&token=edf380a3-2c82-4d08-9ce0-fbf76d4caf9f", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F4.jpeg.png?alt=media&token=edf380a3-2c82-4d08-9ce0-fbf76d4caf9f" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F2.jpeg.png?alt=media&token=15becc98-cdde-4d03-a819-ae3aec993b76", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F2.jpeg.png?alt=media&token=15becc98-cdde-4d03-a819-ae3aec993b76" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_4.jpg.png?alt=media&token=029e6fae-5b88-47f5-9030-a7ddb691e4d7", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_4.jpg.png?alt=media&token=029e6fae-5b88-47f5-9030-a7ddb691e4d7" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_6.jpg.png?alt=media&token=84cedcab-530b-4593-8c0e-e3e9b410262e", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_6.jpg.png?alt=media&token=84cedcab-530b-4593-8c0e-e3e9b410262e" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_7.jpg.png?alt=media&token=dc44c5d5-1d35-4b17-a6e0-28c816606270", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_7.jpg.png?alt=media&token=dc44c5d5-1d35-4b17-a6e0-28c816606270" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F7.jpeg.png?alt=media&token=9d5603fa-235a-4740-8a30-e003343c8faa", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F7.jpeg.png?alt=media&token=9d5603fa-235a-4740-8a30-e003343c8faa" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F9.jpeg.png?alt=media&token=fe8d0f4f-f0fb-4212-80dd-ea49fdcce2eb", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F9.jpeg.png?alt=media&token=fe8d0f4f-f0fb-4212-80dd-ea49fdcce2eb" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F3.jpeg.png?alt=media&token=fa7d1ff3-5b4e-4dc6-a898-0474e2cc5c8e", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F3.jpeg.png?alt=media&token=fa7d1ff3-5b4e-4dc6-a898-0474e2cc5c8e" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F6.jpeg.png?alt=media&token=21ee71d4-83e3-4a18-8462-af12dab45552", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F6.jpeg.png?alt=media&token=21ee71d4-83e3-4a18-8462-af12dab45552" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_6.jpg.png?alt=media&token=e8e8f21c-57ce-4ccc-978b-4512120b9ac0", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_6.jpg.png?alt=media&token=e8e8f21c-57ce-4ccc-978b-4512120b9ac0" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_5.jpg.png?alt=media&token=cadd3bc8-4f11-4821-81e0-ee28c6c97b9e", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_5.jpg.png?alt=media&token=cadd3bc8-4f11-4821-81e0-ee28c6c97b9e" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0002.jpg.png?alt=media&token=afcc5541-aaff-4e1c-9e9a-0f9badf31fc3", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0002.jpg.png?alt=media&token=afcc5541-aaff-4e1c-9e9a-0f9badf31fc3" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_7.jpg.png?alt=media&token=f389189f-af6d-4e5d-b893-a1ed6317cf90", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_7.jpg.png?alt=media&token=f389189f-af6d-4e5d-b893-a1ed6317cf90" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_4.jpg.png?alt=media&token=21dc7280-2019-46d6-816a-57492ef783db", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_4.jpg.png?alt=media&token=21dc7280-2019-46d6-816a-57492ef783db" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_2.jpg.png?alt=media&token=85e955e5-7979-414e-b0f7-e2656ebd6d00", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_2.jpg.png?alt=media&token=85e955e5-7979-414e-b0f7-e2656ebd6d00" },
  // { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0007.jpg.png?alt=media&token=02d15867-339f-4eb0-8065-f32b69a606b8", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0007.jpg.png?alt=media&token=02d15867-339f-4eb0-8065-f32b69a606b8" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0006.jpg.png?alt=media&token=c34bb131-435b-433c-8b71-12492f5027a7", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0006.jpg.png?alt=media&token=c34bb131-435b-433c-8b71-12492f5027a7" },
  // { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0004.jpg.png?alt=media&token=13ff6a19-d63f-44e0-ba25-9f0144e9487a", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0004.jpg.png?alt=media&token=13ff6a19-d63f-44e0-ba25-9f0144e9487a" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0002.jpg.png?alt=media&token=7ca85ec3-87da-4d64-ac9b-31fe0f657d34", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0002.jpg.png?alt=media&token=7ca85ec3-87da-4d64-ac9b-31fe0f657d34" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_5.jpg.png?alt=media&token=21dc2553-63a8-4c4f-a928-3436355ca96b", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_5.jpg.png?alt=media&token=21dc2553-63a8-4c4f-a928-3436355ca96b" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG_20190111_153911.jpg.png?alt=media&token=add46026-90fa-43ba-92ba-ed7a472480f5", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG_20190111_153911.jpg.png?alt=media&token=add46026-90fa-43ba-92ba-ed7a472480f5" },
  { original: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_3.jpg.png?alt=media&token=c5e9cdef-5bb9-4a64-95e4-e286f6833343", thumbnail: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_3.jpg.png?alt=media&token=c5e9cdef-5bb9-4a64-95e4-e286f6833343" },
];
  const obj1 = [
  // { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F1.jpeg.png?alt=media&token=652f149a-5674-4bce-b819-4d232b3c8b3d" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F12.jpeg.png?alt=media&token=51a40f05-b11b-4fa3-b143-065a5e64e5bc" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0003.jpg.png?alt=media&token=6774b652-28cc-482c-bd07-f8676b8422b1" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_1.jpg.png?alt=media&token=b8142cc8-0efd-4f0b-9b97-2adfab83cfa5" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_1.jpg.png?alt=media&token=a0ea0921-cfec-43d8-a97a-32cabac3c11b" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_3.jpg.png?alt=media&token=b81d9fe8-d87c-430b-9276-cf17cf5941eb" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F3.jpeg.png?alt=media&token=fa7d1ff3-5b4e-4dc6-a898-0474e2cc5c8e" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_5.jpg.png?alt=media&token=21dc2553-63a8-4c4f-a928-3436355ca96b" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0018.jpg.png?alt=media&token=008195d2-fe22-4b04-b08a-a6a7cb3b5998" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_7.jpg.png?alt=media&token=dc44c5d5-1d35-4b17-a6e0-28c816606270" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F10.jpeg.png?alt=media&token=65029b2b-9c52-41c4-b1b3-f3e24b8709c2" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F11.jpeg.png?alt=media&token=3cc672f7-2535-4706-9d98-1539809087a6" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F2.jpeg.png?alt=media&token=15becc98-cdde-4d03-a819-ae3aec993b76" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_2.jpg.png?alt=media&token=330613af-c923-424f-acd2-694af4f45f0f" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_6.jpg.png?alt=media&token=84cedcab-530b-4593-8c0e-e3e9b410262e" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F7.jpeg.png?alt=media&token=9d5603fa-235a-4740-8a30-e003343c8faa" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0006.jpg.png?alt=media&token=c34bb131-435b-433c-8b71-12492f5027a7" },
  // { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0004.jpg.png?alt=media&token=13ff6a19-d63f-44e0-ba25-9f0144e9487a" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(2)_4.jpg.png?alt=media&token=029e6fae-5b88-47f5-9030-a7ddb691e4d7" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_7.jpg.png?alt=media&token=f389189f-af6d-4e5d-b893-a1ed6317cf90" },
  // { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0007.jpg.png?alt=media&token=02d15867-339f-4eb0-8065-f32b69a606b8" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_3.jpg.png?alt=media&token=c5e9cdef-5bb9-4a64-95e4-e286f6833343" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_2.jpg.png?alt=media&token=85e955e5-7979-414e-b0f7-e2656ebd6d00" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG_20190111_153911.jpg.png?alt=media&token=add46026-90fa-43ba-92ba-ed7a472480f5" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_6.jpg.png?alt=media&token=e8e8f21c-57ce-4ccc-978b-4512120b9ac0" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220215-WA0002.jpg.png?alt=media&token=7ca85ec3-87da-4d64-ac9b-31fe0f657d34" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_5.jpg.png?alt=media&token=cadd3bc8-4f11-4821-81e0-ee28c6c97b9e" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F6.jpeg.png?alt=media&token=21ee71d4-83e3-4a18-8462-af12dab45552" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F20319016%20Module%20QNo.%20(3)_4.jpg.png?alt=media&token=21dc7280-2019-46d6-816a-57492ef783db" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F9.jpeg.png?alt=media&token=fe8d0f4f-f0fb-4212-80dd-ea49fdcce2eb" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2F4.jpeg.png?alt=media&token=edf380a3-2c82-4d08-9ce0-fbf76d4caf9f" },
  { src: "https://firebasestorage.googleapis.com/v0/b/qr-media.appspot.com/o/dummy_gallery%2FIMG-20220203-WA0002.jpg.png?alt=media&token=afcc5541-aaff-4e1c-9e9a-0f9badf31fc3" },
];
useEffect(() => {
    let imgref = ref(storage, "/dummy_gallery");
    listAll(imgref).then((images) => {
      images.items.forEach((image) => {
        getDownloadURL(image).then((url) => {
          // console.log('{ original: "' + url + '", thumbnail: "' + url + '" },');
          console.log('{ src: "' + url + '" },');
        });
      });
    });
  }, []);
  
  return (
    <div>
      <div className="title-container p-1">
        <div className="title p-2">Porinchu P A</div>
      </div>
      <MobileShare />
      <div className="gallery-container">
        <div className="slides">
          <ImageGallery
            items={obj2}
            autoPlay={true}
            className="slideshow"
            originalClass="slideshow-original"
            thumbnailClass="slideshow-original"
          />
        </div>
        <div className="pt-5">
          <ResponsiveGallery images={obj1} useLightBox={true} />
        </div>
      </div>

      <div className="middle-footer">
        <div>Make your own online gallery</div>
        <div>
          Contact <a href="tel:+91 9048712470">+91 9048712470</a>
        </div>
      </div>
    </div>
  );
}
