import "./landing.scss";

export default function Landing() {
  return (
    <div className="landing_container">
      <div className="title">QRmedia.in</div>
      <div>Make your own online gallery</div>
      <div>Contact <a href="tel:+91 9048712470">+91 9048712470</a></div>
    </div>
  );
}
