import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Landing from "./pages/landing/landing";
import Admin from "./pages/admin/admin";
import GallerySlug from "./pages/gallery_slug/gallery_slug";
import DummyGallery from "./pages/dummy_gallery/dummy_gallery";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/b68uq5yjv9" element={<DummyGallery />} />
        <Route path="/:gallerySlug" element={<Landing />} />
        {/* <Route path="/:gallerySlug" element={<GallerySlug />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
