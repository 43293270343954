import "./admin.scss";
import { UniqueQR } from "../../components/";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function Admin() {
  const [id, setId] = useState("");
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
  };

  return <div className="p-4">Access denied</div>;

  return (
    <div className="admin_container">
      <div className="container title">New Gallery</div>
      <form onSubmit={handleSubmit(onSubmit)} className="admin__form">
        <UniqueQR updateParent={setId} />
        <div className="admin__form__fields">
          <div className="pb-3">
            <label htmlFor="title" className="label">
              Title
            </label>
            <input className="input" {...register("title")} />
          </div>
          <div className="pb-3">
            <label htmlFor="text_1" className="label">
              Text 1
            </label>
            <input className="input" {...register("text_1")} />
          </div>
          <div className="pb-3">
            <label htmlFor="text_2" className="label">
              Text 2
            </label>
            <input className="input" {...register("text_2")} />
          </div>
          <input className="button mt-3" type="submit" />
        </div>
      </form>
    </div>
  );
}
